import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';
import thunk from 'redux-thunk';
import { FormState, reducer as reduxFormReducer } from 'redux-form';

interface RootState {
  form: FormState;
}

export function configureStore(history: History, initialState?: RootState): Store<RootState> {
  let middleware = compose(applyMiddleware(thunk));

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware);
  }

  return createStore<RootState, any, {}, {}>(
    //@ts-ignore
    combineReducers({
      form: reduxFormReducer,
    }),
    initialState,
    middleware
  ) as Store<RootState>;
}
