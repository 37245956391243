import * as React from 'react';
import { useState } from 'react';
import LoginBackground from './Components/LoginBackground/LoginBackground';
import LoginPageTemplate from './Components/LoginPageTemplate/LoginPageTemplate';
import LoginForm from './Components/LoginForm/LoginForm';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { configureStore } from './Store';

// prepare store
const history = createBrowserHistory();
const store = configureStore(history);

function App() {
  const [errorMessage, setErrorMessage] = useState('');
  return (
    <Provider store={store}>
      <LoginBackground>
        <LoginPageTemplate errorText={errorMessage}>
          <LoginForm
            onSubmit={() => {
              setErrorMessage('Could not log you in, please check your credentials');
            }}
            submitText={'Log in'}
          />
        </LoginPageTemplate>
      </LoginBackground>
    </Provider>
  );
}

export default App;
