import styled from 'styled-components';
import { FontsEnum } from '../../Common/FontsEnum';
import { ColorsEnum } from '../../Common/ColorsEnum';

const EllipseInput = styled.input`
  border-radius: 20px;
  width: 280px;
  height: 40px;
  box-sizing: border-box;
  outline: none;
  border: none;
  padding-right: 20px;
  padding-left: 20px;
  font-family: ${FontsEnum.MuseoSans};
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: ${ColorsEnum.INPUT_ACTIVE};
  &:focus {
    border: solid 1px ${ColorsEnum.INPUT_FOCUS_BORDER};
  }
`;

export default EllipseInput;
