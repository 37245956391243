export enum ColorsEnum {
  BUTTON_REGULAR_BACKGROUND = '#323232',
  BUTTON_ACTIVE_BACKGROUND = '#000000',
  INPUT_ACTIVE = '#323232',
  INPUT_FOCUS_BORDER = '#0670a5',
  ERROR_BACKGROUND = '#ee2e24',
  ERROR_TEXT = '#ffffff',
  MESSAGE_TEXT = '#ffffff',
  LOGIN_BACKGROUND = 'linear-gradient(304deg, #00c8ff, #08a0ed 98%)',
  SUPPORT_EMAIL_TEXT = '#323232',
  SUPPORT_TEXT = '#ffffff',
}
