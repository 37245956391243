import 'babel-polyfill';
import 'core-js';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';

function renderApp() {
  ReactDOM.render(<App />, document.getElementById('root'));
}

(function init() {
  serviceWorker.register();
  renderApp();
})();
