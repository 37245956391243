import * as React from 'react';
import styled from 'styled-components';
import copilotLogo from '../../Resources/Images/copilot-logo.svg';
import { ReactNode } from 'react';
import ColumnContainer from '../ColumnContainer/ColumnContainer';
import MessageText from '../MessageText/MessageText';
import GeneralError from '../GeneralError/GeneralError';

const StyledColumnContainer = styled(ColumnContainer)`
  align-items: flex-start;
  width: ${props => (props.width ? `${props.width}px` : 'initial')};
  padding-top: 186px;
`;

const StyledImg = styled.img`
  object-fit: contain;
`;

const StyledTitle = styled(MessageText)`
  margin-bottom: 13px;
`;

const StyledMessage = styled(MessageText)`
  margin-top: 13px;
  margin-bottom: 34px;
`;

const StyledError = styled(GeneralError)`
  position: absolute;
  top: 59px;
  right: calc(50% - 224px);
  align-self: center;
`;

interface Props {
  children?: ReactNode;
  errorText?: string;
  message?: string;
}

const LoginPageTemplate: React.StatelessComponent<Props> = props => {
  return (
    <StyledColumnContainer width={280}>
      {props.errorText && <StyledError text={props.errorText} />}
      <StyledTitle text={'Welcome'} />
      <StyledImg src={copilotLogo} />
      <StyledMessage text={props.message} />
      {props.children}
    </StyledColumnContainer>
  );
};

export default LoginPageTemplate;
