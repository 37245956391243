import styled from 'styled-components';
import * as React from 'react';
import {FontsEnum} from "../../Common/FontsEnum";
import {ColorsEnum} from "../../Common/ColorsEnum";
import ColumnContainer from "../ColumnContainer/ColumnContainer";
import RowContainer from "../RowContainer/RowContainer";

const SUPPORT_LINK = 'support@copilot.cx';

const StyledLink = styled.a`
  text-decoration: none;
`;

const StyledLabel = styled.label`
  font-family: ${FontsEnum.MuseoSans};
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${ColorsEnum.SUPPORT_EMAIL_TEXT};
  cursor: pointer;
`;

const MailToLink: React.StatelessComponent<{}> = () => (
  <StyledLink href={`mailto:${SUPPORT_LINK}`} target="_top">
    <StyledLabel>{SUPPORT_LINK}</StyledLabel>
  </StyledLink>
);

const StyledLoginSupportContainer = styled(ColumnContainer)`
  margin-left: 32px;
  margin-bottom: 28px;
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const StyledContactMessage = styled(StyledLabel)`
  margin-right: 5px;
  color: white;
`;

const SupportTitle = styled.label`
  font-family: ${FontsEnum.MuseoSans};
  font-size: 14px;
  font-weight: 900;
  line-height: 1.43px;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${ColorsEnum.SUPPORT_TEXT};
  margin-bottom: 10px;
`;

const LoginSupport: React.StatelessComponent<{}> = () => (
  <StyledLoginSupportContainer>
    <SupportTitle>{'Problem logging in?'}</SupportTitle>
    <RowContainer>
      <StyledContactMessage>{'Contact us at '}</StyledContactMessage>
      <MailToLink />
    </RowContainer>
  </StyledLoginSupportContainer>
);

export default LoginSupport;
