import styled from 'styled-components';
import * as React from 'react';
import {ColorsEnum} from "../../Common/ColorsEnum";
import {FontsEnum} from "../../Common/FontsEnum";

interface Props {
  text: string;
  className?: string;
}

const StyledContainer = styled.div`
  width: 448px;
  height: 40px;
  border-radius: 20px;
  background-color: ${ColorsEnum.ERROR_BACKGROUND};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled.label`
  font-family: ${FontsEnum.MuseoSans};
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: ${ColorsEnum.ERROR_TEXT};
`;

function GeneralError(props: Props) {
  return (
    <StyledContainer className={props.className}>
      <StyledText>{props.text}</StyledText>
    </StyledContainer>
  );
}

export default GeneralError;
