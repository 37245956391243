import * as React from 'react';
import styled from 'styled-components';
import { WrappedFieldProps } from 'redux-form';
import { FontsEnum } from '../../Common/FontsEnum';
import { ColorsEnum } from '../../Common/ColorsEnum';
import RowContainer from '../RowContainer/RowContainer';
import EllipseInput from '../EllipseInput/EllipseInput';
import { IconsEnum } from '../../Resources/Icons/IconsEnum';

const StyledIcon = styled.div`
  font-size: 12px;
  margin-right: 5px;
`;

const StyledErrorLabel = styled.label`
  font-family: ${FontsEnum.MuseoSans};
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${ColorsEnum.INPUT_ACTIVE};
`;

const StyledRowContainer = styled(RowContainer)`
  align-items: center;
  padding-left: 20px;
  margin-top: 10px;
`;

interface Props {
  marginBottom?: number;
}

const ReduxFormEllipseInput: React.StatelessComponent<Props &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps> = props => {
  const { input, type, placeholder, meta } = props;
  const { touched, error } = meta;

  return (
    <div style={{ marginBottom: `${props.marginBottom}px` }}>
      <EllipseInput {...input} placeholder={placeholder} type={type} />
      {touched &&
        error && (
          <StyledRowContainer>
            <StyledIcon className={IconsEnum.WARNING_TRIANGLE} />
            <StyledErrorLabel>{error}</StyledErrorLabel>
          </StyledRowContainer>
        )}
    </div>
  );
};

export default ReduxFormEllipseInput;
