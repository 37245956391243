import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';

const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ColumnContainerProps {
  setHovering?: (hovering: boolean) => void;
  className?: string;
  children: ReactNode;
  width?: number;
}

const ColumnContainer: React.FunctionComponent<ColumnContainerProps> = props => (
  <StyledColumnContainer
    className={props.className}
    onMouseEnter={() => props.setHovering && props.setHovering(true)}
    onMouseLeave={() => props.setHovering && props.setHovering(false)}>
    {props.children}
  </StyledColumnContainer>
);

export default ColumnContainer;
