import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface RowContainerProps {
  className?: string;
  children: ReactNode;
  title?: string;
}

const RowContainer: React.StatelessComponent<RowContainerProps> = props => (
  <StyledRowContainer title={props.title} className={props.className}>
    {props.children}
  </StyledRowContainer>
);

export default RowContainer;
