import * as React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { ColorsEnum } from '../../Common/ColorsEnum';
import { FontsEnum } from '../../Common/FontsEnum';

const StyledButton = styled.button`
  background-color: ${ColorsEnum.BUTTON_REGULAR_BACKGROUND};
  border-radius: 20px;
  width: 103px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: white;
  font-family: ${FontsEnum.MuseoSlab};
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    background-color: ${ColorsEnum.BUTTON_ACTIVE_BACKGROUND};
    opacity: 1;
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;

interface EllipseButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
  type: string;
  text: string;
  className?: string;
}

const EllipseButton: React.StatelessComponent<EllipseButtonProps> = props => (
  <StyledButton
    className={props.className}
    disabled={props.isDisabled}
    type={props.type}
    data-test-id={`${props.type}-button`}>
    {props.isLoading ? <CircularProgress size={25} thickness={8} /> : props.text}
  </StyledButton>
);

export default EllipseButton;
