import * as React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import ReduxFormEllipseInput from '../ReduxFormEllipseInput/ReduxFormEllipseInput';
import { ReactNode } from 'react';
import styled from 'styled-components';
import EllipseButton from '../EllipseButton/EllipseButton';
import { isNotEmpty } from '../../Common/ValidationFunctionsUtils';
import { FormsNamesEnum } from '../../Common/FormsNamesEnum';
import RowContainer from '../RowContainer/RowContainer';

const StyledEllipseButton = styled(EllipseButton)`
  align-self: flex-end;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;
const StyledRowContainer = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;

const required = (value: string) => (isNotEmpty(value) ? undefined : 'This field is required');

export interface LoginFormData {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (data: LoginFormData) => void;
  submitText: string;
  secondaryButton?: ReactNode;
}

const LoginForm: React.FunctionComponent<Props &
  InjectedFormProps<LoginFormData, Props>> = props => {
  const { handleSubmit, pristine, submitting, invalid } = props;

  return (
    //@ts-ignore
    <StyledForm data-test-id="login-form" onSubmit={handleSubmit(props.onSubmit)}>
      <Field
        name="email"
        placeholder={'email'}
        component={ReduxFormEllipseInput}
        validate={[required]}
        marginBottom={10}
      />
      <Field
        name="password"
        type="password"
        placeholder={'password'}
        component={ReduxFormEllipseInput}
        validate={[required]}
        marginBottom={30}
      />
      <StyledRowContainer>
        <StyledEllipseButton
          isDisabled={pristine || submitting || invalid}
          isLoading={submitting}
          type={'submit'}
          text={props.submitText}
        />
      </StyledRowContainer>
    </StyledForm>
  );
};

export default reduxForm<LoginFormData, Props>({
  form: FormsNamesEnum.LoginForm,
})(LoginForm);
