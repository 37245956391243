export enum IconsEnum {
  WARNING_TRIANGLE = 'icon-ic-error',
  EXPAND_ARROW = 'icon-ic-expend',
  SIDE_BAR_MENU_COLLAPSED = 'icon-ic-menu',
  SIDE_BAR_COPILOT = 'icon-ic-copilot',
  SIDE_BAR_SUPPORT = 'icon-ic-support',
  SIDE_BAR_ANALYZE = 'icon-ic-analyze',
  SIDE_BAR_ENGAGE = 'icon-ic-engage',
  SIDE_BAR_OPERATE = 'icon-ic-operate',
  SIDE_BAR_NPS = 'icon-ic-nps',
  SIDE_BAR_SALES = 'icon-ic-sales',
  INPUT_SEARCH = 'icon-ic-search',
  CHEVRON_RIGHT = 'icon-ic-chevron-right',
  CANCEL = 'icon-ic-x',
  MORNING_ICON = 'icon-ic-early-morning',
  EVENING_ICON = 'icon-ic-evening',
  NIGHT_ICON = 'icon-ic-night',
  DAYTIME_ICON = 'icon-ic-daytime',
  USER_ICON = 'icon-ic-user',
  THING_ICON = 'icon-ic-thing',
  LINK = 'icon-ic-link',
  ANONYMOUS_USER_ICON = 'icon-ic-anonymous-user',
  ACTIVATE = 'icon-ic-activate',
  EMAIL = 'icon-ic-email',
  EXPAND_SCREEN = 'icon-ic-expand-screen',
  COLLAPSE_SCREEN = 'icon-ic-collapse-screen',
  AUDIENCE = 'icon-ic-audience',
  UPLOAD = 'icon-ic-upload',
  DOWNLOAD = 'icon-ic-download-24-px',
  EMOJI_SMILE = 'icon-ic-emoji',
  STATUS_QUESTION_MARK = 'icon-ic-status-quetion-mark',
  STATUS_CHECKED_MARK = 'icon-ic-status-checked-mark',
  STATUS_EXCLAMATION_MARK = 'icon-ic-status-exclamation-mark',
  PURCHASES_DISCOUNT = 'icon-ic-discount',
  REWARDS = 'icon-ic-reward',
  PLUS = 'icon-ic-plus',
  MERGE_HORIZONTAL = 'icon-merge-horizontal',
  INFORMATION = 'icon-ic-information',
}
