import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import LoginSupport from './LoginSupport';
import loginBgPic from '../../Resources/Images/login-bg-pic.png';
import RowContainer from '../RowContainer/RowContainer';
import { ColorsEnum } from '../../Common/ColorsEnum';

const StyledContainer = styled(RowContainer)`
  width: 100vw;
  height: 100vh;
  background-image: ${ColorsEnum.LOGIN_BACKGROUND};
  justify-content: space-between;
`;

const StyledLoginBgPic = styled.img`
  height: 85%;
  align-self: flex-end;
`;

const StyledChildrenWrapper = styled(RowContainer)`
  width: 100%;
  justify-content: center;
`;

interface LoginBackground {
  children: ReactNode;
}

const LoginBackground: React.StatelessComponent<LoginBackground> = props => {
  return (
    <StyledContainer>
      <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
      <StyledLoginBgPic src={loginBgPic} />
      <LoginSupport />
    </StyledContainer>
  );
};

export default LoginBackground;
