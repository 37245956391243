import * as React from 'react';
import styled from 'styled-components';
import { FontsEnum } from '../../Common/FontsEnum';
import { ColorsEnum } from '../../Common/ColorsEnum';

const StyledLabel = styled.label`
  font-family: ${FontsEnum.MuseoSans};
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${ColorsEnum.MESSAGE_TEXT};
`;

interface MessageText {
  text?: string;
  className?: string;
}

const MessageText: React.StatelessComponent<MessageText> = props => (
  <StyledLabel className={props.className}>{props.text}</StyledLabel>
);

export default MessageText;
